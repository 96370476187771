<template>
  <div class="modify-button-container" @click="onClick">
    <div class="content">
      اعمال تفییرات
    </div>
  </div>
</template>

<script>
export default {
  name: "ModifyButton",
  props: {
    onClick: {
      type: Function,
      require: true
    }
  }
};
</script>

<style scoped>
.modify-button-container {
  width: 300px;
  height: 40px;
  background: #1f3c88;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.content {
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
}

@media (max-width: 560px) {
  .modify-button-container {
    width: 100%;
  }
}
</style>
