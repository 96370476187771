<template>
  <div class="input-container background">
    <img
      v-if="url || preview"
      :src="url || preview"
      width="100%"
      height="100%"
      class="image-wrapper"
    />
    <input
      class="input"
      type="file"
      accept="image/x-png,image/gif,image/jpeg"
      @change="onFileChange"
    />
  </div>
</template>

<script>
export default {
  name: "UploadInput",
  props: {
    value: {},
    preview: String
  },
  data() {
    return {
      url: this.value ? URL.createObjectURL(this.value) : null
    };
  },
  methods: {
    onFileChange(e) {
      const file = e.target.files[0];
      this.$emit("input", file);
      this.url = URL.createObjectURL(file);
    }
  }
};
</script>

<style scoped>
.input-container {
  max-width: 435px;
  width: 100%;
  height: 296px;
  border: 1px dashed #070d59;
  border-radius: 10px;
  z-index: 122;
  background: url("../assets/images/bi_cloud-upload.svg") rgba(31, 60, 136, 0.1)
    no-repeat center;
  position: relative;
}

.input {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.image-wrapper {
  border-radius: 10px;
}

@media (max-width: 560px) {
  .input-container {
    min-width: 100%;
  }
}
</style>
