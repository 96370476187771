<template>
  <div class="date-picker-input-wrapper">
    <span class="label">تاریخ تولد</span>
    <label class="input-container">
      <input
        class="input"
        type="text"
        :value="dateValue"
        id="my-custom-input"
      />
      <img class="icon" src="../../assets/images/uil_calender.svg" alt="" />
    </label>
    <datePicker element="my-custom-input" v-model="dateValue" />
  </div>
</template>

<script>
import VuePersianDatetimePicker from "vue-persian-datetime-picker";

export default {
  name: "DatePickerInput",
  props: ["value"],
  data() {
    return {
      dateValue: this.value
    };
  },
  watch: {
    dateValue(newValue) {
      this.$emit("input", newValue);
    },
    value(newValue) {
      this.dateValue = newValue;
    }
  },
  components: {
    datePicker: VuePersianDatetimePicker
  }
};
</script>

<style scoped>
.date-picker-input-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 280px;
  min-width: 280px;
  width: 100%;
}

.input-container {
  display: flex;
  align-items: center;
  background: rgba(31, 60, 136, 0.2);
  border-radius: 5px;
  padding: 0px 10px;
}

.input {
  outline: none;
  border: none;
  background: transparent;
  /*border-radius: 5px;*/
  padding: 5px 10px;
  color: rgba(18, 18, 18, 0.7);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: right;
  width: 100%;
}

.icon {
}

.label {
  color: #121212;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}
</style>
