<template>
  <div class="warning-alert-container" v-if="show">
    <div class="img">
      <img src="../assets/images/fluent_warning-24-regular.svg" alt="" />
    </div>
    <div class="content">
      اکانت شما بصورت کامل احراز هویت نشده است و لطفا همه اطلاعات را تکمیل
      نمایید.
    </div>
    <div class="close" @click="close">
      <img src="../assets/images/jam_close.svg" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "WarningAlert",
  data() {
    return {
      show: true
    };
  },
  methods: {
    close() {
      this.show = false;
    }
  }
};
</script>

<style scoped>
.warning-alert-container {
  width: 100%;
  min-height: 50px;
  /*max-height: auto;*/
  border-radius: 10px;
  background: rgba(220, 0, 0, 0.1);
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
}

.content {
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  color: #dc0000;
  flex: 1;
  margin-right: 5px;
}

.text-color {
  cursor: pointer;
  color: red;
}

.close {
  cursor: pointer;
}

.close:hover img {
  opacity: 0.8;
}

img {
  vertical-align: middle;
}

@media (min-width: 768px) {
  .content {
    font-size: 16px;
  }
}
</style>
