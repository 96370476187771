<template>
  <div class="button-container" @click="$emit('click')">
    <img src="../../assets/images/fe_upload.svg" alt="" />
    <div class="content">
      ارسال تصویر
    </div>
  </div>
</template>

<script>
export default {
  name: "UploadButton"
};
</script>

<style scoped>
.button-container {
  min-width: 435px;
  height: 40px;
  background: #1f3c88;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.content {
  color: #ffffff;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  margin-right: 10px;
}

@media (max-width: 560px) {
  .button-container {
    min-width: 100%;
  }
}
</style>
