<template>
  <div class="account-authentication-container">
    <loading :can-cancel="true"></loading>

    <PanelHeader title="حساب کاربری" />
    <div class="account-authentication-wrapper">
      <section class="alert">
        <WarningAlert />
      </section>
      <section class="form-section">
        <div class="form-container">
          <div class="form-header">
            احراز هویت در اکسچنج از بسیار ساده و سریع میباشد و فقط یک بخش دارد
            که ارسال تصویر سلفی به همراه مدارک شناسایی میباشد که در این مرحله
            مدراک شما باید توسط پشتیبانی تایید گردد که در کمترین زمان ممکن این
            کار انجام میشود.
          </div>
          <div class="form-group">
            <div class="img">
              <div class="profile-image">
                <input
                  class="profile-input"
                  type="file"
                  @change="profileImageHandler"
                />
              </div>
            </div>
            <div class="form-element-wrapper">
              <div class="first row">
                <GrayInput
                  label="نام"
                  type="text"
                  placeholder="نام را وارد کنید"
                  v-model="user.first_name"
                  min-length="3"
                  ref="firstname"
                  required="true"
                  pattern="fa"
                />
                <GrayInput
                  label="نام خانوادگی"
                  type="text"
                  placeholder="نام خانوادگی را وارد کنید"
                  v-model="user.last_name"
                  min-length="2"
                  ref="lastname"
                  required="true"
                  pattern="fa"
                />
                <GrayInput
                  label="شماره موبایل"
                  type="text"
                  placeholder="شماره موبایل را وارد کنید"
                  v-model="user.mobile"
                  min-length="11"
                  ref="mobile"
                  required="true"
                  disabled="true"
                />
              </div>
              <div class="second row">
                <GrayInput
                  label="آدرس ایمیل"
                  type="email"
                  placeholder="آدرس ایمیل را وارد کنید"
                  v-model="user.email"
                  min-length="3"
                  ref="email"
                  required="true"
                  pattern="email"
                />
                <GrayInput
                  label="کد ملی"
                  type="text"
                  placeholder="کد ملی را وارد کنید"
                  v-model="user.national_code"
                  min-length="9"
                  ref="nationalCode"
                  pattern="nc"
                />
                <datePickerInput
                  v-model="user.date_of_birth"
                  :value="user.date_of_birth"
                />
              </div>
              <div class="first row">
                <GrayInput
                  label="استان"
                  type="text"
                  placeholder="استان محل سکونت را وارد کنید"
                  v-model="user.province"
                  min-length="2"
                  ref="province"
                  pattern="fa"
                />
                <GrayInput
                  label="شهر"
                  type="text"
                  placeholder="شهر محل سکونت را وارد کنید"
                  v-model="user.city"
                  min-length="2"
                  ref="city"
                  pattern="fa"
                />
                <GrayInput
                  label="تلفن ثابت(همراه با کد شهر یا شهرستان)"
                  type="text"
                  placeholder="تلفن ثابت با کد شهر یا شهرستان"
                  v-model="user.phone"
                  min-length="11"
                  ref="phone"
                  pattern="number"
                />
              </div>
            </div>
          </div>
          <div class="information-text">
            برای تاییداطلاعات توسط پشتیبان ما پس از ورود اطلاعات عکس کارت ملی
            خود را به صورتی که اطلاعات روی کارت واضح باشد در پایین بارگذاری
            کنید. توجه داشته باشید که اطلاعات وارد شده در بالا باید کاملا مطابق
            اطلاعات روی کارت ملی باشد
          </div>
          <div class="file-upload">
            <div class="upload-image">
              <img src="../assets/images/image.svg" alt="" width="100%" />
            </div>
            <div class="upload-input">
              <UploadInput v-model="documentImage" :preview="nc_image" />
              <UploadButton @click="handleUploadImage" class="upload-button" />
            </div>
          </div>
        </div>
      </section>
    </div>
    <ModifyButton :onClick="submit" class="modify-button" />
  </div>
</template>

<script>
import PanelHeader from "@/components/PanelHeader";
import WarningAlert from "@/components/WarningAlert";
import GrayInput from "@/components/input/GrayInput";
import UploadInput from "@/components/UploadInput";
import UploadButton from "@/components/Button/UploadButton";
import ModifyButton from "@/components/Button/ModifyButton";
import datePickerInput from "@/components/input/DatePickerInput";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import axios from "axios";

export default {
  components: {
    ModifyButton,
    UploadButton,
    UploadInput,
    GrayInput,
    WarningAlert,
    PanelHeader,
    datePickerInput,
    Loading
  },
  data() {
    return {
      user: {},
      nc_image: null,
      profile_image: null,
      profile_image_url: null,
      date: "",
      documentImage: null
    };
  },
  mounted() {
    this.getUserInformation();
  },
  methods: {
    submit() {
      this.$refs.firstname.validateForm();
      if (this.$refs.firstname.errorMassage) {
        this.$refs.firstname.focus();
        return false;
      }

      this.$refs.lastname.validateForm();
      if (this.$refs.lastname.errorMassage) {
        this.$refs.lastname.focus();
        return false;
      }

      this.$refs.email.validateForm();
      if (this.$refs.email.errorMassage) {
        this.$refs.email.focus();
        return false;
      }

      if (this.$refs.nationalCode.errorMassage) {
        this.$refs.nationalCode.focus();
        return false;
      }

      if (this.$refs.province.errorMassage) {
        this.$refs.province.focus();
        return false;
      }

      if (this.$refs.city.errorMassage) {
        this.$refs.city.focus();
        return false;
      }

      if (this.$refs.phone.errorMassage) {
        this.$refs.phone.focus();
        return false;
      }

      let loader = this.$loading.show({
        loader: "bars",
        color: "#080F66"
      });

      axios({
        url: "panel/account",
        data: this.user,
        method: "PUT"
      })
        .then(response => {
          localStorage.removeItem("user");
          localStorage.setItem("user", JSON.stringify(response.data.user));
          this.$store.state.user = response.data.user;
          loader.hide();
          this.$toast.success(response.data.message);
        })
        .catch(error => {
          loader.hide();
          this.$toast.error(error.response.message);
          // console.log(error.response);
        });
    },
    handleUploadImage() {
      if (!this.documentImage) {
        this.$toast.warning("عکس را برای ارسال انتخاب نکرده اید");
        return false;
      }

      let formData = new FormData();
      formData.append("image", this.documentImage);
      let loader = this.$loading.show({
        loader: "bars",
        color: "#080F66"
      });
      axios({
        url: "/panel/account/upload-document",
        data: formData,
        method: "POST"
      })
        .then(response => {
          loader.hide();
          if (!response.data.status) {
            this.$toast.warning(response.data.message);
            return false;
          }
          this.$toast.success(response.data.message);
        })
        .catch(error => {
          loader.hide();
          console.log(error.response);
        });
    },
    getUserInformation() {
      let loader = this.$loading.show({
        loader: "bars",
        color: "#080F66"
      });
      axios("/panel/account")
        .then(response => {
          console.log(response.data);
          this.user = response.data.user;
          this.nc_image = response.data.nc_image.image;
          this.profile_image = response.data.profile_image.image;
          loader.hide();
        })
        .catch(error => {
          if (error.response.status === 401) {
            loader.hide();
            this.$alert(
              "شما دسترسی لازم را ندارید",
              "عدم ورود به سایت",
              "error"
            );
          }
          if (error.response.status === 500) {
            loader.hide();
            this.$alert(
              "ارتباط با سرور قطع شده است لطفا دوباره تلاش کنید",
              "خطایی رخ داده است",
              "error"
            );
          }
        });
    },
    profileImageHandler(e) {
      const file = e.target.files[0];
      this.profile_image = file;
      this.profile_image_url = URL.createObjectURL(file);
    }
  }
};
</script>

<style scoped>
.account-authentication-container {
  width: calc(100% - 250px);
  margin-right: auto;
}

.account-authentication-wrapper {
  padding: 0 40px;
}

.alert {
  margin-top: 20px;
}

.form-container {
  margin-top: 11px;
  width: 100%;
  min-height: 1082px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  padding: 20px 30px 20px 30px;
}

.form-element-wrapper {
  padding: 0 70px;
}

.form-header {
  color: #121212;
  font-style: normal;
  font-weight: normal;
}

.img {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.profile-image {
  cursor: pointer;
  height: 150px;
  width: 150px;
  border-radius: 100px;
  background: url("../assets/images/profile.svg") no-repeat;
}

.profile-image:hover {
  background: url("../assets/images/profile-edit.svg") no-repeat;
}

.profile-input {
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}

.row {
  display: inline-flex;
  gap: 40px;
  flex-wrap: wrap;
  margin-top: 40px;
}

.information-text {
  margin-top: 60px;
}

.file-upload {
  display: flex;
  justify-content: space-around;
  margin: 60px;
  flex-wrap: wrap;
}

.upload-button {
  margin-top: 20px;
}

.modify-button {
  margin: 60px auto 115px auto;
}

@media (max-width: 992px) {
  .account-authentication-container {
    width: 100%;
  }
}

@media (max-width: 560px) {
  .account-authentication-container {
    padding: 0 10px;
  }

  .account-authentication-wrapper {
    padding: 0;
  }

  .form-element-wrapper {
    padding: 0;
  }

  .upload-image {
    width: 100%;
  }

  .file-upload {
    margin: 20px auto;
    /*margin: auto;*/
  }

  .upload-input {
    width: 100%;
  }
}
</style>
